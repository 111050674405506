import React from 'react';
import getTranslations from '../../utils/getTranslations';
import { TitleAndSubtitle } from '../shared/titleAndSubtitle';
import './privacyPolicy.scss';

export const PrivacyPolicy: React.FC = () => {
  const p = getTranslations().privacy!;

  return (
    <div id='privacy-policy-container'>
      <div className='rounded-bottom-corner' id='privacy-policy-title'>
        <TitleAndSubtitle title={p.title!} subtitle={p.subtitle!} />
      </div>

      <div id='privacy-policy-text' className='main-padding'>
        <div className='column is-8 is-offset-2'>
          <h2>{p.summaryTitle}</h2>
          <p>{p.summaryText1}</p>
          <p>{p.summaryText2}</p>
          <h2>{p.collectTitle}</h2>
          <div>
            <ol>
              <li>{p.collectText1}</li>
              <li>{p.collectText2}</li>
              <li>{p.collectText3}</li>
              <li>{p.collectText4}</li>
              <li>{p.collectText5}</li>
              <li>{p.collectText6}</li>
            </ol>
          </div>

          <h2>{p.legalTitle}</h2>
          <p>{p.legalText}</p>
          <h2>{p.storingTitle}</h2>
          <p>{p.storingText}</p>
          <h2>{p.childrenTitle}</h2>
          <p>{p.childrenText}</p>
          <h2>{p.contactTitle}</h2>
          <p>{p.contactText}</p>
        </div>
      </div>
    </div>
  );
};
