import React from 'react';
import { PrivacyPolicy } from '../components/privacy/privacyPolicy';

const PrivacyPage: React.FC = () => {
  return (
    <div>
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPage;
